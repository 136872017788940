<template>
  <div class="text-center">
    <h2 class="text-h2">Выход</h2>
    <v-progress-circular
      indeterminate
      :size="100"
      :width="5"
      color="primary"
    />
    <p class="text-h6">
      Подождите, идёт загрузка...
    </p>
  </div>
</template>

<script>
export default {
  name: 'Logout',
  created () {
    this.$store.dispatch('auth/logout', { vm: this }).then(() => {
      this.$router.push('/')
    })
  }
}
</script>
